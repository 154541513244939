const TRANSITION_END = 'transitionend'
const onTransitionEnd = (el, callback, propertyName) => {
  let timeoutId
  const eventHandler = event => {
    if (!event || !propertyName || event.propertyName === propertyName) {
      clearTimeout(timeoutId);
      el.removeEventListener(TRANSITION_END, eventHandler);
      callback();
    }
  }
  timeoutId = setTimeout(eventHandler, 1000); //in case event never fires
  el.addEventListener(TRANSITION_END, eventHandler);
}

export {
  onTransitionEnd
}
