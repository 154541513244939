import React, { Component } from 'react';

class Header extends Component {
  render() {
    return (
      <header className="App-header">
        <div className="hdr-logo">
          <a className="logo" href="/" title="Travelers Home Page"><span className="sr">Travelers Home Page</span></a>
        </div>
      </header>
    );
  }
}

export default Header;