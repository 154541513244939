import React, { Component } from 'react';
import { Fragment } from 'react'

//error messages
const cityError = 'City is required';
const emailError = 'A valid email address is required';
const agencyError = 'An agency name is required';
const roleError = 'Please select your role';
const stateError = 'Please select your state';
const validEmailRegex = RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i);

//grab from local
let captcha = '';
let existingEmail = (localStorage.getItem('email')) ? localStorage.getItem('email') : "";
let existingRole = (localStorage.getItem('role')) ? localStorage.getItem('role') : "Select option";
let existingFname = (localStorage.getItem('fname')) ? localStorage.getItem('fname') : "";
let existingLname = (localStorage.getItem('lname')) ? localStorage.getItem('lname') : "";
let existingAgency = (localStorage.getItem('agency')) ? localStorage.getItem('agency') : "";
let existingCity = (localStorage.getItem('city')) ? localStorage.getItem('city') : "";
let existingState = (localStorage.getItem('state')) ? localStorage.getItem('state') : "Select your state";
let existingPrimeCode = (localStorage.getItem('prime code')) ? localStorage.getItem('prime code') : "";
let existingSalesExec = (localStorage.getItem('sales executive')) ? localStorage.getItem('sales executive') : "";

function onCaptchaChange(value) {
  captcha = value;
  console.log("Captcha value:", value);
}

class Form extends Component {
  constructor() {
    super();
    this.state = {
      email: existingEmail,
      role: existingRole,
      fname: existingFname,
      lname: existingLname,
      agency: existingAgency,
      city: existingCity,
      state: existingState,
      primeCode: existingPrimeCode,
      salesExec: existingSalesExec,
      showCityError: false,
      showEmailError: false,
      showAgencyError: false,
      showRoleError: false,
      showCaptchaError: false,
      validForm: false,
    };

    this.validateForm = this.validateForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeFormHistory = this.changeFormHistory.bind(this);
    //this.focusError = this.focusError.bind(this);

    window.addEventListener('popstate',  this.changeHistory);
  }

  changeFormHistory(e) {
    var ptStep = parseInt(window.location.hash.replace('#',''),10); // get step
    
    if (ptStep === 0) { //if at form, populate
      this.setState({
        email: existingEmail,
        role: existingRole,
        fname: existingFname,
        lname: existingLname,
        agency: existingAgency,
        city: existingCity,
        state: existingState,
        primeCode: existingPrimeCode,
        salesExec: existingSalesExec,
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const {email, role, agency, city, state} = this.state;
    this.validateForm(email, role, agency, city, state);
  }

  validateForm(email, role, agency, city, state) {  //check these individually for now

    if (!validEmailRegex.test(email)) {
      this.setState({
        showEmailError: true,
      });
      var emailValid = false;
    } else {
      this.setState({
        showEmailError: false,
      });
      emailValid = true;
    }
 
    if (role === 'Select option' || role.length === 0) {
      this.setState({
        showRoleError: true,
      });
      var roleValid = false;
    } else {
      this.setState({
        showRoleError: false,
      });
      roleValid = true;
    }

    if (city.length === 0) {
      this.setState({
        showCityError: true,
      });
      var cityValid = false;
    } else {
      this.setState({
        showCityError: false,
      });
      cityValid = true;
    }

    if (state === 'Select your state' || state.length === 0) {
      this.setState({
        showStateError: true,
      });
      var stateValid = false;
    } else {
      this.setState({
        showStateError: false,
      });
      stateValid = true;
    }

    if (agency.length === 0) {
      this.setState({
        showAgencyError: true,
      });
      var agencyValid = false;
    } else {
      this.setState({
        showAgencyError: false,
      });
      agencyValid = true;
    }
    this.handleData(emailValid, roleValid, cityValid, stateValid, agencyValid);
  }

  handleData(emailValid, roleValid, cityValid, stateValid, agencyValid) {
    const {email, role, fname, lname, agency, city, state, primeCode, salesExec} = this.state;

    var validationResults = [emailValid, roleValid, agencyValid, cityValid, stateValid];
    console.log(validationResults);
    if (validationResults.includes(false)) {  //if what we care about is valid
      console.log('not valid');

      var firstError = validationResults.indexOf(false);
      document.getElementsByClassName('validate')[firstError].focus();

    } else {
      console.log('valid');
      window.localStorage.setItem('email', email);
      window.localStorage.setItem('role', role);
      window.localStorage.setItem('fname', fname);
      window.localStorage.setItem('lname', lname);
      window.localStorage.setItem('agency', agency);
      window.localStorage.setItem('city', city);
      window.localStorage.setItem('state', state);
      window.localStorage.setItem('prime code', primeCode);
      window.localStorage.setItem('sales executive', salesExec);
      this.props.parentMethod(); //begin test
    }
  }

  focusField() {
    console.log('focus');
    var firstError = document.getElementsByClassName('input-error')[0];

    if (firstError) {
      firstError.focus();
    }
  }

  render() {

    return (
      <div className="pt-form">
        <form autoComplete="off" aria-hidden="false" onSubmit={this.handleSubmit}>
          <p className="form-error" tabIndex="0" id="hdr-login-frmError" role="alert" aria-live="assertive"></p>

          
          <fieldset>
          <legend>About You</legend>
            <div className="col-lg-6">
              <div className="input-group tds-field" role="group">
                <label htmlFor="email" className="tds-field__label">Email</label>
                <input autoComplete="no" aria-invalid={this.state.showEmailError} onChange={e => this.setState({ email: e.target.value })} id="email" value={this.state.email} className={`validate tds-field__input ${this.state.showEmailError ? 'input-error' :''}`} name="email" type="text" />
                {this.state.showEmailError ? 
                  <Fragment>
                    <p className="caption error" id="cityError" role="alert">
                      {emailError}
                    </p>
                  </Fragment>
                :null
                }
              </div>
            </div>

            <div className="col-lg-6">
              <div className="input-group dropdown tds-field" role="group">
                <label className="tds-field__label" htmlFor="role">Role</label>
                <select aria-invalid={this.state.showRoleError} onChange={(e)=>{this.setState({role: e.target.value})}} value={this.state.role} className={`validate tds-dropdown ${this.state.showRoleError ? 'input-error' :''}`} name="role" id="role">
                  <option value="Select option" aria-label="Select option" disabled>Select option</option>
                  <option value="Owner/Principal" aria-label="Owner/Principal">Owner/Principal</option>
                  <option value="PL Manager" aria-label="PL Manager">PL Manager</option>
                  <option value="Producer/CSR" aria-label="Producer/CSR">Producer/CSR</option>
                  <option value="Other" aria-label="Other">Other</option>
                </select>
                {this.state.showRoleError ? 
                  <Fragment>
                    <p className="caption error" id="roleError" role="alert">
                      {roleError}
                    </p>
                  </Fragment>
                  :null
                }
              </div>
            </div>

             <div className="col-lg-6">
              <div className="input-group tds-field" role="group">
                <label htmlFor="fname" className="tds-field__label">Name (Optional)</label>
                <input autoComplete="no" noValidate onChange={e => this.setState({ fname: e.target.value })} id="fname" placeholder="First Name" value={this.state.fname} className="tds-field__input" name="fname" type="text" />
              </div>
            </div>

             <div className="col-lg-6">
              <div className="input-group tds-field" role="group">
                <label htmlFor="lname" className="tds-field__label empty"><span className="screen-readers-only">Last Name</span></label>
                <input autoComplete="no" noValidate onChange={e => this.setState({ lname: e.target.value })} id="lname" placeholder="Last Name" value={this.state.lname} className="tds-field__input" name="lname" type="text" />
                <p className="caption error" id="lnameError" role="alert"></p>
              </div>
            </div>
          </fieldset>


          
          <fieldset>
          <legend>About Your Agency</legend>
          <div className="col-lg-6">
            <div className="input-group tds-field" role="group">
              <label htmlFor="agency" className="tds-field__label">Agency Name</label>
              <input autoComplete="no" aria-invalid={this.state.showAgencyError} onChange={e => this.setState({ agency: e.target.value })} id="agency" value={this.state.agency} className={`validate tds-field__input ${this.state.showAgencyError ? 'input-error' :''}`} name="agency" type="text" />
                {this.state.showAgencyError ? 
                  <Fragment>
                    <p className="caption error" id="agencyError" role="alert">
                      {agencyError}
                    </p>
                  </Fragment>
                :null
                }
            </div>
          </div>

          <div className="col-lg-6 col-clear">
            <div className="input-group tds-field" role="group">
              <label className="tds-field__label" htmlFor="city">City</label>
              <input autoComplete="no" aria-invalid={this.state.showCityError} onChange={e => this.setState({ city: e.target.value })} id="city" value={this.state.city} className={`validate tds-field__input ${this.state.showCityError ? 'input-error' :''}`} name="city" type="text" />
              {this.state.showCityError ? 
                <Fragment>
                  <p className="caption error" id="cityError" role="alert">
                    {cityError}
                  </p>
                </Fragment>
              :null
              }
            </div>
          </div>
         
             <div className="col-lg-6">
              <div className="input-group tds-field dropdown" id="dropdown-states">
                <label className="tds-field__label" htmlFor="state">State</label>
                <select aria-invalid={this.state.showStateError} onChange={e => this.setState({ state: e.target.value })} className={`validate tds-dropdown ${this.state.showStateError ? 'input-error' :''}`} value={this.state.state} name="state" id="state">
                  <option value="Select your state" aria-label="Select your state" disabled>Select your state</option>
                  <option aria-label="Alabama" value="AL">Alabama</option>
                  <option aria-label="Alaska" value="AK">Alaska</option>
                  <option aria-label="Arizona" value="AZ">Arizona</option>
                  <option aria-label="Arkansas" value="AR">Arkansas</option>
                  <option aria-label="California" value="CA">California</option>
                  <option aria-label="Colorado" value="CO">Colorado</option>
                  <option aria-label="Connecticut" value="CT">Connecticut</option>
                  <option aria-label="Delaware" value="DE">Delaware</option>
                  <option aria-label="District Of Columbia" value="DC">District Of Columbia</option>
                  <option aria-label="Florida" value="FL">Florida</option>
                  <option aria-label="Georgia" value="GA">Georgia</option>
                  <option aria-label="Hawaii" value="HI">Hawaii</option>
                  <option aria-label="Idaho" value="ID">Idaho</option>
                  <option aria-label="Illinois" value="IL">Illinois</option>
                  <option aria-label="Indiana" value="IN">Indiana</option>
                  <option aria-label="Iowa" value="IA">Iowa</option>
                  <option aria-label="Kansas" value="KS">Kansas</option>
                  <option aria-label="Kentucky" value="KY">Kentucky</option>
                  <option aria-label="Louisiana" value="LA">Louisiana</option>
                  <option aria-label="Maine" value="ME">Maine</option>
                  <option aria-label="Maryland" value="MD">Maryland</option>
                  <option aria-label="Massachusetts" value="MA">Massachusetts</option>
                  <option aria-label="Michigan" value="MI">Michigan</option>
                  <option aria-label="Minnesota" value="MN">Minnesota</option>
                  <option aria-label="Mississippi" value="MS">Mississippi</option>
                  <option aria-label="Missouri" value="MO">Missouri</option>
                  <option aria-label="Montana" value="MT">Montana</option>
                  <option aria-label="Nebraska" value="NE">Nebraska</option>
                  <option aria-label="Nevada" value="NV">Nevada</option>
                  <option aria-label="New Hampshire" value="NH">New Hampshire</option>
                  <option aria-label="New Jersey" value="NJ">New Jersey</option>
                  <option aria-label="New Mexico" value="NM">New Mexico</option>
                  <option aria-label="New York" value="NY">New York</option>
                  <option aria-label="North Carolina" value="NC">North Carolina</option>
                  <option aria-label="North Dakota" value="ND">North Dakota</option>
                  <option aria-label="Ohio" value="OH">Ohio</option>
                  <option aria-label="Oklahoma" value="OK">Oklahoma</option>
                  <option aria-label="Oregon" value="OR">Oregon</option>
                  <option aria-label="Pennsylvania" value="PA">Pennsylvania</option>
                  <option aria-label="Rhode Island" value="RI">Rhode Island</option>
                  <option aria-label="South Carolina" value="SC">South Carolina</option>
                  <option aria-label="South Dakota" value="SD">South Dakota</option>
                  <option aria-label="Tennessee" value="TN">Tennessee</option>
                  <option aria-label="Texas" value="TX">Texas</option>
                  <option aria-label="Utah" value="UT">Utah</option>
                  <option aria-label="Vermont" value="VT">Vermont</option>
                  <option aria-label="Virginia" value="VA">Virginia</option>
                  <option aria-label="Washington" value="WA">Washington</option>
                  <option aria-label="West Virginia" value="WV">West Virginia</option>
                  <option aria-label="Wisconsin" value="WI">Wisconsin</option>
                  <option aria-label="Wyoming" value="WY">Wyoming</option>
                </select>
                {this.state.showStateError ? 
                  <Fragment>
                      <p className="caption error" id="stateError" role="alert">{stateError}</p>
                  </Fragment>
                  :null
                }
              </div>
            </div>
             <div className="col-lg-6">
              <div className="input-group tds-field" role="group">
                <label className="tds-field__label" htmlFor="primeCode">Agency Prime Code (if known)</label>

                <input autoComplete="no" noValidate onChange={e => this.setState({ primeCode: e.target.value })} id="primeCode" value={this.state.primeCode} className="tds-field__input" name="primeCode" type="text" />
                <p className="caption error" id="prime-codeError" role="alert"></p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="input-group tds-field" role="group">
                <label className="tds-field__label" htmlFor="salesExec">Travelers Sales Executive (if known)</label>
                <input autoComplete="no" noValidate onChange={e => this.setState({ salesExec: e.target.value })} id="salesExec" value={this.state.salesExec} className="tds-field__input" name="salesExec" type="text" />
                <p className="caption error" id="sales-execError" role="alert"></p>
              </div>
            </div>
    
          <div className="col-lg-12">
            <button className="button--primary" id="button-header-login">Begin</button>
          </div>
        </fieldset>
        </form>
      </div>
    );
  }
}

export default Form;
