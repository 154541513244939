/**
 * Gets the target element from an element's attribute. Used by elements that 'trigger' another component
 * Target reference can be an id or a selector
 * Checks 3 atttributes
 * - aria-controls
 * - attribute, which defaults to 'data-target'
 * - href
 * @param element with the target or href attribute
 * @param attribute - The attribute to check after aria-controls. Defaults to 'data-target'
 * @return {*}
 */
function getTargetElement(element, attribute = 'data-target') {
  let selector = element.getAttribute('aria-controls') || element.getAttribute(attribute)
  if (!selector) {
    const href = element.getAttribute('href')
    selector = href && href[0]==='#' && href.length > 1 ? href.trim() : ''
  }

  try {
    return document.getElementById(selector) || document.querySelector(selector) || null
  } catch (err) {
    return null
  }
}

export default getTargetElement
