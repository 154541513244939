import React, { Component } from 'react';

class Questions extends Component {

  render() {

    return (
      <div className="pt-disclaimer">
        <p className="pt-disclaimer__p">Disclaimer: The Future-Readiness Assessment is for general information only. The information you provide in the tool will be used to provide Travelers with insights that may be used to help build new ‘future-ready’ tools and capabilities for our agents. Responses will also be shared with the Personal Insurance Field Sales Organization and we may contact you to discuss your results. We will not give or sell your information to any third party for marketing purposes. We may update and/or change the tool without notice.</p>
      </div>
    );
  }
}

export default Questions;