import React, { Component } from 'react'
import { onTransitionEnd } from '../shared/animation'
import getTargetElement from '../shared/getTargetElement'

const MODAL_TRIGGER_ATTR = 'data-trigger-modal'
const MODAL_OPEN_BODY_CLASS = `cssns-body--modal-open`

const CANDIDATES = `
  a, button, input, select, textarea, svg, area, details, summary,
  iframe, object, embed, 
  [tabindex], [contenteditable]
`;

const trapFocus = (focusNode, rootNode = document) => {
  const nodes = [...rootNode.querySelectorAll(CANDIDATES)]
    .filter(node => !focusNode.contains(node) && node.getAttribute('tabindex') !== '-1');
  nodes.forEach(node => node.setAttribute('tabindex', '-1'));
  return {
    release() {
      const nodes = [...rootNode.querySelectorAll(CANDIDATES)]
      nodes.forEach(node => node.removeAttribute('tabindex'));
    },
  };
};

class Modal extends Component {

  constructor(element, config = {}) {
    super(element, config)
  }

  open() {
    const element = document.getElementById('my-modal-medium');

    if (element && element.hidden) {

      setBodyModalOpenState(true, this)
      const modal = document.querySelector(`[aria-modal="true"]`);
      trapFocus(modal); // focus is trapped

      onTransitionEnd(element, () => {
        if (element.hidden) {
          return  // in case closed before transition ended
        }
      })
      element.hidden = false
    }
  }


  close(e) {
    const element = document.getElementById('my-modal-medium');
    const modal = document.querySelector(`[aria-modal="true"]`);
    element.hidden = true;
    trapFocus(modal).release(); // focus is released
    document.getElementById('my-modal-small-launch').focus();
  }

  render() {

    const tooltip = this.props.tooltipContent;

    return (
      <div id="my-modal-medium" className="cssns-modal cssns-modal__dialog--medium" hidden aria-modal="true" role="dialog">
        <div className="cssns-modal__overlay" onClick={this.close}></div>
        <div className="cssns-modal__dialog-container" aria-labelledby="my-modal-heading7"
          aria-describedby="my-modal-description2" role="document">
          <div className="cssns-modal__dialog-header">
            <h2 id="my-modal-heading7" className="cssns-modal__dialog-title">
              More Info
            </h2>
          </div>
          <div id="my-modal-body2" className="cssns-modal__dialog-body">
            <div id="my-modal-description2" className="cssns-modal__dialog-body-content">
              <p>{tooltip}</p>
            </div>
          </div>
          <button aria-label="Dismiss dialog" className="cssns-modal__dismiss" id="close-button" onClick={this.close}>&times;</button>
        </div>
      </div>
    );
  }
}

function setBodyModalOpenState(open, modal) {
  const body = document.body
  if (open) {
    const vScrollBarWidth = window.innerWidth - body.clientWidth
    if (vScrollBarWidth) {
      body.style.paddingRight = `${vScrollBarWidth}px`
    }
    document.body.classList.add(MODAL_OPEN_BODY_CLASS)
  }
  else {
    onTransitionEnd(modal.element, () => {
      body.style.removeProperty('padding-right')
      body.classList.remove(MODAL_OPEN_BODY_CLASS)
    }, 'opacity')
  }
}

function preventIOSScroll(open, modal) {
  const privateData = modal._private
  if (open) {
    if (window.CSS && CSS.supports && CSS.supports("-webkit-overflow-scrolling: touch")) {
      privateData.stored_scrollTop = window.pageYOffset || document.body.scrollTop
      setTimeout(() => {
        document.body.style.position = "fixed"
        document.body.style.top = `${-privateData.stored_scrollTop}px`
        document.body.style.right = "0"
        document.body.style.bottom = "0"
        document.body.style.left = "0"
      }, 1000);
    }
  } else {
    const body_pos = window.getComputedStyle(document.body).position
    if (body_pos === "fixed") {
      document.body.style.removeProperty("position")
      document.body.style.removeProperty("top")
      document.body.style.removeProperty("left")
      document.body.style.removeProperty("bottom")
      document.body.style.removeProperty("right")
      window.scrollTo(window.pageXOffset, privateData.stored_scrollTop)
    }
  }
}

/**
 * @description A class to initialize a modal element
 * @class
 * @param {DomElement} element - the dom element
 * @param {config} object - config
 */

document.addEventListener('click', function (e) {
  if (e.target.id === 'my-modal-small-launch') {

    const trigger = e.target;

    if (trigger) {
      const modalEl = getTargetElement(trigger, MODAL_TRIGGER_ATTR)
      new Modal(modalEl, { relatedTarget: trigger }).open()
      if (trigger.tagName === 'A' || trigger.tagName === 'AREA') {
        e.preventDefault()
      }
    }
  }
})


export default Modal;