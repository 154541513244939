import React, { Component } from 'react';
import Data from './data/questions.json';
import Header from './components/Header';
import Buttons from './components/Buttons';
import Results from './components/Results';
import Form from './components/Form';
import Footer from './components/Footer';
import Disclaimer from './components/Disclaimer';
import Modal from './components/Modal';
import './App.scss';

const totalQuestions = 32;
var ptStep = 1;  // default to first question
const questionsList = Data.questions;  //get json
var currentQuestion = (questionsList[0].question);
var currentSection = (questionsList[0].section);
var finalTally;
var existingAnswers = JSON.parse(localStorage.getItem('answers')); //get previous answers from localstorage, in case of refresh
var capturePath = window.location.pathname;  //get path
if (window.location.hash) { //if there's a hash
    var captureStep = parseInt(window.location.hash.replace('#',''),10); // grab it
  if (existingAnswers) {  //if we have previous answers, populate the tally
    var tally = existingAnswers;
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.error = React.createRef();

    if (capturePath === '/' || capturePath === '') {   //we're home, show intro
      this.state = {
        showIntro: true,
        showForm: false,
        showProgressBar: false,
        showQuestions: false,
        showResults: false,
        ptStep: 1,
        progressBarStyle: 0,
        currentSection: currentSection,
        currentQuestion: currentQuestion,
        showFreeform: false,
        freeFormText: '',
        showError: false,
        answer: '',
      };
    } else if (capturePath === '/question/') {  //got questions, initialize accordingly
      if (captureStep === 0) { // this is the form step
        this.state = {
          showIntro: false,
          showForm: true,
          showProgressBar: true,
          showQuestions: false,
          showResults: false,
          ptStep: 0,
          progressBarStyle: 1,
          currentSection: currentSection,
          currentQuestion: currentQuestion,
          showFreeform: false,
          freeFormText: '',
          showError: false,
          answer: '',
        };
      } else {
        if (existingAnswers) {
          this.state = {
            showIntro: false,
            showProgressBar: true,
            showQuestions: true,
            showResults: false,
            ptStep: captureStep,
            progressBarStyle: Math.round((captureStep-1) / totalQuestions * 100),
            currentSection: questionsList[captureStep-1].section,
            currentQuestion: questionsList[captureStep-1].question,
            showFreeform: false,
            showError: false,
            answer: '',
          };
         } else {  //redirect when user tries to hit /questions without previous answers
          window.location.href = '/';
        }
      }
    } else if (capturePath === '/results/' || capturePath === '/results') {  //initialize accordingly
      if (existingAnswers) {
        this.state = {
          showIntro: false,
          showProgressBar: true,
          showQuestions: false,
          showResults: true,
          ptStep: 33,
          progressBarStyle: 100,
          currentSection: currentSection,
          currentQuestion: currentQuestion,
          showFreeform: false,
          showError: false,
        };
      } else {  //redirect when user tries to hit /results without previous answers
        window.location.href = '/';
      }
    } else {
      window.location.href = '/';  //bad URL, go home
    }

    this.submitAnswer = this.submitAnswer.bind(this);
    this.changeHistory = this.changeHistory.bind(this);
    this.enterText = this.enterText.bind(this);
    this.beginTest = this.beginTest.bind(this);
    this.clickRadio = this.clickRadio.bind(this);
  }

  showHighlight(prevAnswerValue) {  // highlights previously selected button
    if (prevAnswerValue) {
      if (prevAnswerValue === "Yes") {
        this.setState({
          showHighlightYes: true,
          showHighlightNo: false,
        });
      } else {
        this.setState({
          showHighlightNo: true,
          showHighlightYes: false,
        });
      }
    } else {
      this.setState({
        showHighlightYes: false,
        showHighlightNo: false,
      });
    }
  }

  showFreeformAnswer(prevFreeform, prevAnswerValue) {  //show freeform box and populate it
    if (prevFreeform) { //if there's a freeform question
        this.setState({
          showFreeform: true,
        });
      if (prevAnswerValue === 'N/A') {
        this.setState({
          freeFormText: '',
        });
      } else {  
        this.setState({
          freeFormText: prevAnswerValue,
        });
      }
    } else {
      this.setState({
        showFreeform: false,
        freeFormText: '',
      });
    }
  }

  showTooltip(prevTooltip) {
    this.setState({
      currentTooltip: prevTooltip,
    });
  }

  clickModal(e) {
    e.preventDefault();
    document.getElementById('close-button').focus();
  }

  showPreviousButton(ptStep) {
    if (ptStep === 1) {
      this.setState({
        showPrevious: false,
      });
    }
  }

  retakeTest() {
    window.location.href = window.location.origin;
    localStorage.setItem('emailSent', false);
  }

  gotoForm(browserBack) {
    this.setState({
      ptStep: 0,
      showIntro: false,
      showProgressBar: true,
      showForm: true,
      progressBarStyle: 0,
      showQuestions: false,
      showPrevious: false,
    });
    if (!browserBack) {
      window.history.pushState(null, null, "/question/#0");
    }
  }

  beginTest() {
    this.setState({
      ptStep: 1,
      showIntro: false,
      showForm: false,
      showProgressBar: true,
      progressBarStyle: 1,
      showQuestions: true,
      showPrevious: false,
    });
    window.history.pushState(null, null, "/question/#1");
    document.getElementById('pressure-test-main').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    localStorage.setItem('emailSent', false);
  }
  
  componentDidMount() { //handles refresh scenario
    window.addEventListener('popstate',  this.changeHistory);
    if (captureStep > 0) {
      var prevFreeform = (questionsList[captureStep-1].freeform);
      if (prevFreeform) {
        this.setState({
          showFreeform: true,
        });
      }
    }
    
    if (existingAnswers && window.location.hash) {  //if we have previous answers on refresh, populate the tally
      //set these vars based on a refresh
      if (captureStep > 0) { //if we are beyond the form
        var prevAnswerKey = Object.keys(tally)[captureStep-1]; 
        var prevAnswerValue = tally[prevAnswerKey];
        var prevTooltip = (questionsList[captureStep-1].tooltip);

        if (prevAnswerValue) {
          this.setState({
            showPrevious: true,
            answer: prevAnswerValue,
          });
        } else {
          this.setState({
            showPrevious: true,
            answer: '',
          });
        }
        this.showPreviousButton(captureStep)
        this.showHighlight(prevAnswerValue);
        this.showFreeformAnswer(prevFreeform, prevAnswerValue);
        this.showTooltip(prevTooltip);
      } else {
        this.gotoForm();
      }
    } else {
      tally = {
        [ptStep]: "1"
      }
    }
  }

  changeHistory(e) {
    if(window.location.hash) { //if there's a hash
      var ptStep = parseInt(window.location.hash.replace('#',''),10); // grab it
      if (ptStep === totalQuestions) {
        this.setState({
          showResults: false,
          showProgressBar: true,
          showQuestions: true,
        });
      }
    } else {
      ptStep = null;
    }
    
    if (ptStep > 0) {
      var nextQuestion = (questionsList[ptStep-1].question);
      var nextSection =  (questionsList[ptStep-1].section);
      var nextTooltip = (questionsList[ptStep-1].tooltip);
      var nextFreeform = (questionsList[ptStep-1].freeform);
      var progressBarPercent = Math.round((ptStep-1) / totalQuestions * 100);
      //set these vars based on a change in browser history
      var prevAnswerKey = Object.keys(tally)[ptStep-1];
      var prevAnswerValue = tally[prevAnswerKey];
      var prevFreeform = (questionsList[ptStep-1].freeform);

      this.showHighlight(prevAnswerValue);
      this.showFreeformAnswer(prevFreeform, prevAnswerValue);

      if (nextFreeform) {
        this.setState({showFreeform: true});
      }

      this.setState({
        ptStep: ptStep,
        progressBarStyle: progressBarPercent,
        currentSection: nextSection,
        currentQuestion: nextQuestion,
        currentTooltip: nextTooltip,
        answer: prevAnswerValue,
        showError: false,
      });

    } else if (ptStep === 0) { //go to form
      var browserBack = true;
      this.gotoForm(browserBack);

    } else { //back to intro
        this.setState({
        showIntro: true,
        showForm: false,
        showQuestions: false,
        showProgressBar: false,
      });
      window.history.pushState(null, null, "/");  
    }
  }

  enterText(e) {  // capture the freeform answers
    this.setState({
      freeFormText: e.target.value,
    });
  }

  clickRadio(e) {
    this.setState({
      answer: e.target.value,
    });
    var radioId = e.target.id;
    document.getElementById(radioId).parentElement.classList.add('pt-label-highlight');
    document.getElementById(radioId).checked = true;
    if (radioId === 'yes') {
      document.getElementById('no').parentElement.classList.remove('pt-label-highlight');
    } else {
      document.getElementById('yes').parentElement.classList.remove('pt-label-highlight');
    }
  }

  submitAnswer(e) {  //clicking next
    if (window.location.hash) { //if there's a hash
      var ptStep = parseInt(window.location.hash.replace('#',''),10); // grab it
    } else {
      ptStep = 0; //set to zero
    }

    if (this.state.answer === '' && !(questionsList[ptStep-1].freeform)) {  //check for invalid answer
      this.setState({
        showError: true,
      });
      
    } else {
      if (this.state.showFreeform) {  //if we have a freeform answer, append to Yes/No
          tally[ptStep] = (this.state.freeFormText ? this.state.freeFormText : 'N/A');  //add freeform
        } else {
          tally[ptStep] = this.state.answer;  //add answer
      }

      if (ptStep < totalQuestions) { //can't go past 32

        var nextQuestion = (questionsList[ptStep].question); //step determines question shown
        var nextSection = (questionsList[ptStep].section);
        var nextTooltip = (questionsList[ptStep].tooltip);
        var prevFreeform = (questionsList[ptStep].freeform);
        var prevAnswerKey = Object.keys(tally)[ptStep];

        if (prevAnswerKey) {
          var prevAnswerValue = tally[prevAnswerKey];
        } else {
          prevAnswerValue = '';
        }

        this.showFreeformAnswer(prevFreeform, prevAnswerValue);
        this.showHighlight(prevAnswerValue);

        window.localStorage.setItem('answers', JSON.stringify(tally));

        var progressBarPercent = Math.round(ptStep / totalQuestions * 100);

        ptStep++; //advance
        if (this.state.showPrevious) {
          document.getElementById('previous').focus();
        }

        this.setState({
          ptStep: ptStep,
          progressBarStyle: progressBarPercent,
          currentQuestion: nextQuestion,
          currentSection: nextSection,
          currentTooltip: nextTooltip,
          showPrevious: true,
          showFreeform: prevFreeform,
          answer: prevAnswerValue,
          showError: false,
        });

        if (ptStep > 1) {
          window.history.pushState(null, null, "/question/#" + ptStep);
        }
        document.getElementById('waypoint').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      } else {

        finalTally = Object.keys(tally).map(function(e) {
          return tally[e]
        })

        window.localStorage.setItem('answers', JSON.stringify(tally));

        this.setState({
          showIntro: false,
          showProgressBar: true,
          showQuestions: false,
          showResults: true,
          ptStep: 32,
          progressBarStyle: 100,
          currentSection: currentSection,
          currentQuestion: currentQuestion,
          showFreeform: false,
          showHighlightNo: false,
          showHighlightYes: false,
        });
        window.history.pushState(null, null, "/results");
      }
    }
  }

  previousQuestion() {  //back button clicked
    if(window.location.hash) { //if there's a hash
      var ptStep = parseInt(window.location.hash.replace('#',''),10); // grab it
    } else {
        ptStep = 0; //set to zero
    }

    if (ptStep > 1) { //can't go less than 1
      ptStep--;  //go back

      var prevQuestion = (questionsList[ptStep - 1].question);
      var prevSection = (questionsList[ptStep - 1].section);
      var progressBarPercent = Math.round((ptStep - 1) / totalQuestions * 100);
      var prevFreeform = (questionsList[ptStep-1].freeform);
      var prevTooltip = (questionsList[ptStep-1].tooltip);
      var prevAnswerKey = Object.keys(tally)[ptStep-1]; //get last answer
      var prevAnswerValue = tally[prevAnswerKey];

      this.showHighlight(prevAnswerValue);
      this.showFreeformAnswer(prevFreeform, prevAnswerValue);
      this.showTooltip(prevTooltip);
      
      this.setState({
        ptStep: ptStep,
        progressBarStyle: progressBarPercent,
        currentQuestion: prevQuestion,
        currentSection: prevSection,
        answer: prevAnswerValue,
        showError: false,
      });
      window.history.pushState(null, null, "/question/#" + ptStep);
    }
    this.showPreviousButton(ptStep);
  }
  
  fixTrademarks(str) {
	  let out = str.replace('®', '<sup><small>®</small></sup>');
    out = out.replace('©', '<sup><small>©</small></sup>');
	  out = out.replace('&reg;', '<sup><small>&reg;</small></sup>');
    out = out.replace('&copy;', '<sup><small>&copy;</small></sup>');
	  return out;
  }

  render() {

    return (
      <div className="App wrapper">

        <Header />

        <div id="pressure-test-main" className={`travelers-home ${this.state.showResults ? 'pt-results-page' : 'pt-u-grey'}`} data-quiz-id="4">

            <section role="main" className="claim-xd-styles">
              <section className="pt-logo-progress-bar__partial">
                <header className="pt-logo">
                  <h1 className="pt-logo__h1">
                    <img className="pt-logo__h1-img" src={require('./images/pressure-gauge.svg')} alt="" />
                      Future-Readiness Assessment
                  </h1>
                </header>
                {this.state.showIntro
                  ? <article className="pt-intro-card" id="pt-panel">
                    <header className="col-lg-6 pt-intro-card__header">
                      <h2 className="pt-intro-card__h2">Is your agency future-ready?</h2>
                    </header>
                    <section className="col-lg-6 pt-intro-card__content">
                      <div className="pt-intro-card__wrapper">
                        <p className="pt-intro-card__p">Learn where your agency's strengths and opportunities lie and get customized recommendations to reach your future-ready potential.</p>
                        <p className="pt-intro-card__p">In less than 15 minutes, you’ll receive a customized assessment of how ‘future-ready’ your agency is, based on your answers to questions in four key categories:</p>
                          <ul>
                            <li>Consumer Marketing</li>
                            <li>Consultative Sales</li>
                            <li>Customer Service</li>
                            <li>Agency Operations</li>
                          </ul>
                        
                        <button type="button" className="btn btn-primary cta" onClick={() => this.gotoForm()}>TAKE THE ASSESSMENT</button>
                      </div>
                    </section>
                  </article>
                  : null
                }

                {this.state.showProgressBar
                  ? <div className="pt-progress-bar__wrapper">
                      <div className="pt-progress-bar__primary">
                        <div className="pt-progress-bar__loader pt-progress-bar__loader--lime-green" style={{width: this.state.progressBarStyle + '%'}}></div>
                      </div>
                    </div>
                   : null
                }
              </section>
              {this.state.showResults ? (
                <Results answers={finalTally} beginAgain={this.retakeTest} />
              ) : (
                null
              )}
                
              {this.state.showForm ? (
                  <Form parentMethod={this.beginTest} />
              ) : (
                null
              )}

              {this.state.showQuestions
                ? 
                  <div className="pt-question-card">
                    <div className="row">
                      <div id="waypoint"></div>
                      <div id="topnext" className="col-lg-6">

                      {this.state.showPrevious
                        ? <button className="pt-button-plain-text back" id="previous" value="Previous Question" onClick={() => this.previousQuestion()}>back to previous question</button>
                          : null
                        }

                        <header key={this.state.ptStep} className="pt-question-card__header fade-in" id="question-element">
                          <h2 className="pt-question-card__h2">STEP {this.state.ptStep} OF {totalQuestions} <br /> 
                            <span className="section-stack" id="section">{this.state.currentSection}</span>
                          </h2>
                          <h3 id="question" className="react-reveal tds-h1--small mob-tds-h1--small"><span dangerouslySetInnerHTML={{ __html: this.fixTrademarks(this.state.currentQuestion) }} /></h3>
                          {this.state.currentTooltip
                            ? <button id="my-modal-small-launch" aria-label="More Info" aria-describedby="question" data-trigger-modal="my-modal-small" onClick={this.clickModal}>More</button>
                            : null
                          }
                        </header>
                      </div>

                      <div className="col-lg-6 pt-question-card__col">

                        <div className="pt-question-card__content">
                        {this.state.showError
                          ? <div id="error" role="alert" ref={this.error} className='tds-inline-alert--error' tabIndex="0"><p><span className="tds-sr-only">Informational: </span>Please select an answer below</p></div>
                          : null
                        }
                          <div key={this.state.ptStep} className="react-reveal pt-question-card__button-wrapper fade-in">
                            <div className="row pt-button-group-row">
                              <div className="col-xs-12">
                              <form>
                                <fieldset>
                                <legend className="screen-readers-only">{this.state.currentQuestion}</legend>
                                  {this.state.showFreeform
                                    ? 
                                      <div className="pt-question-card__freeform-wrapper">
                                        <label htmlFor="freeform" className="tds-field__label">Comments (optional)</label>
                                        <textarea id="freeform" value={this.state.freeFormText} onChange={this.enterText} />
                                      </div>
                                    : 
                                      <Buttons highlightYes={this.state.showHighlightYes} highlightNo={this.state.showHighlightNo} parentMethod={this.clickRadio} />
                                  }
                                  </fieldset>
                                </form>
                                <div className="pt-question-card__cta-wrapper">
                                  <button type="button" className="btn btn-primary" value="" onClick={this.submitAnswer}>NEXT</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                : null
              }
            </section>
          <Disclaimer />
        </div>
        <Footer />
        <Modal tooltipContent={this.state.currentTooltip} />
      </div>
    )
  }
}

export default App;
