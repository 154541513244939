import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className="tds-global-footer" role="contentinfo">
        <div className="tds-global-footer__wrapper">
          <div className="tds-global-footer__identity">
            <img className="tds-global-footer__logo" src={require('../images/travelers-logo.svg')} alt="Travelers logo" />
            <p className="tds-global-footer__copy">Travelers and The Travelers Umbrella are registered trademarks of The Travelers Indemnity Company in the U.S. and other countries.<br />© 2020 The Travelers Indemnity Company. All rights reserved.</p>
          </div>
          <ul className="tds-global-footer__social">
              <li>
                  <a className="tds-global-footer__social-item" href="http://www.facebook.com/travelers" title="Facebook">
                      <span className="screen-readers-only">Travelers on Facebook</span>
                      <img className="fb" src={require('../images/fb.svg')} alt="" />
                  </a>
              </li>
              <li>
                  <a className="tds-global-footer__social-item" href="http://www.youtube.com/user/TravelersInsurance" title="YouTube">
                      <span className="screen-readers-only">Travelers on YouTube</span>
                      <img className="yt" src={require('../images/yt.svg')} alt="" />
                  </a>
              </li>
              <li>
                  <a className="tds-global-footer__social-item" href="http://twitter.com/Travelers" title="Twitter">
                      <span className="screen-readers-only">Travelers on Twitter</span>
                      <img className="twitter" src={require('../images/twitter.svg')} alt="" />
                  </a>
              </li>
              <li>
                  <a className="tds-global-footer__social-item" href="http://us.linkedin.com/company/travelers" title="LinkedIn">
                      <span className="screen-readers-only">Travelers on LinkedIn</span>
                      <img className="li" src={require('../images/li.svg')} alt="" />
                  </a>
              </li>
              <li>
                  <a className="tds-global-footer__social-item" href="http://www.instagram.com/travelersinsurance" title="Instagram">
                      <span className="screen-readers-only">Travelers on Instagram</span>
                      <img className="ig" src={require('../images/ig.svg')} alt="" />
                  </a>
              </li>
          </ul>
        </div>
      </footer>
    );
  }
}

export default Footer;