import React, { Component } from 'react';
import { Fragment } from 'react'

class Buttons extends Component {
  
  render() {

    return (
      <Fragment>
        {this.props.highlightYes
        ?
        <label htmlFor="yes" className="tds-check pt-label-highlight">
            <input type="radio" id="yes" name="answer" value="Yes" defaultChecked onChange={this.props.parentMethod} />
            <span className="tds-check__label">Yes</span>
        </label>
        :
        <label htmlFor="yes" className="tds-check">
          <input type="radio" id="yes" name="answer" value="Yes" onFocus={this.props.parentMethod} onChange={this.props.parentMethod} />
          <span className="tds-check__label">Yes</span>
        </label>
        }

        {this.props.highlightNo
        ?
        <label htmlFor="no" className="tds-check pt-label-highlight">
          <input type="radio" id="no" name="answer" value="No" defaultChecked onChange={this.props.parentMethod} />
          <span className="tds-check__label">No</span>
        </label>
        :
        <label htmlFor="no" className="tds-check">
          <input type="radio" id="no" name="answer" value="No" onFocus={this.props.parentMethod} onChange={this.props.parentMethod} />
          <span className="tds-check__label">No</span>
        </label>
      }
      </Fragment>
    )
  }
}

export default Buttons;