import React, { Component } from 'react';
import { Fragment } from 'react'

class Results extends Component {

  constructor(props) {
    super(props);
    this.state = {showLoader: true, showResults: true};
  }

  componentDidMount() {
    document.getElementById('loading-announcement').focus();  
  
    setTimeout(() => {
      document.getElementById('loader-container').classList.add('hidden');  //faux loading
      document.getElementById('results-container').classList.add('fade-in');
      document.getElementById('your-results').focus();
    }, 1500)
  }

  clickSeeMore(e) {
    e.preventDefault();
    document.getElementById('more').focus();
  }

  render() {

    const existingAnswers = JSON.parse(localStorage.getItem('answers'));
    if (this.props.answers) {  //if we have fresh answers
      var tally = this.props.answers;
    } else {  //use localStorage
      tally = Object.values(existingAnswers);
    }

    const yesAnswer = 'Yes';
    const totalAnswers = 28;

    //divide into sections
    var section1 = tally.slice(0, 7);
    var section2 = tally.slice(8, 15);
    var section3 = tally.slice(16, 23);
    var section4 = tally.slice(24, 31);
    var freeform1 = tally[7];
    var freeform2 = tally[15];
    var freeform3 = tally[23];
    var freeform4 = tally[31];

    //gimme all the yeses
    function countYes(n, val) {
      var newVal = val;  //remove freeform text for calc
      return n + (newVal === yesAnswer);
    }

    //set a var for each yes count
    var yesCountOverall = tally.reduce(countYes, 0);
    var yesCountSection1 = section1.reduce(countYes, 0);
    var yesCountSection2 = section2.reduce(countYes, 0);
    var yesCountSection3 = section3.reduce(countYes, 0);
    var yesCountSection4 = section4.reduce(countYes, 0);

    console.log(yesCountOverall);
    console.log('1:' + yesCountSection1);
    console.log('2:' + yesCountSection2);
    console.log('3:' + yesCountSection3);
    console.log('4:' + yesCountSection4);


    function getPercent(count) {
      if (count === 0) {
        return Math.round(count + 2) //give img width in case of zero yes count
      } else {
        return Math.round(count)
      }
    }

    //set a var for the percent
    var overallPercent = getPercent(yesCountOverall / totalAnswers * 100);
    var section1Percent = getPercent(yesCountSection1 / 7 * 100);
    var section2Percent = getPercent(yesCountSection2 / 7 * 100);
    var section3Percent = getPercent(yesCountSection3 / 7 * 100);
    var section4Percent = getPercent(yesCountSection4 / 7 * 100);

    console.log('1%' + section1Percent);
    console.log('2%' + section2Percent);
    console.log('3%' + section3Percent);
    console.log('4%' + section4Percent);

    function between(x, min, max) {
      return x >= min && x <= max; 
    }

    //score it
    function getScore(percent) {
      if (between(percent, 0, 25)) {
        return 'Low';
      } else if (between(percent, 26, 75)) {
        return 'Medium';
      } else {
        return "High";
      }
    }

    //get color
    function getColor(percent) {
      if (between(percent, 0, 25)) {
        return 'orange';
      } else if (between(percent, 26, 75)) {
        return 'yellow';
      } else {
        return "green";
      }
    }

    const section1Color = getColor(section1Percent);
    const section2Color = getColor(section2Percent);
    const section3Color = getColor(section3Percent);
    const section4Color = getColor(section4Percent);

    const overallScore = getScore(overallPercent);
    const section1Score = getScore(section1Percent);
    const section2Score = getScore(section2Percent);
    const section3Score = getScore(section3Percent);
    const section4Score = getScore(section4Percent);
    const answersCombined = section1 + ',' + section2 + ',' + section3 + ',' + section4;
    const freeformsCombined = freeform1 + '|||' +  freeform2 + '|||' +  freeform3 + '|||' +  freeform4;

    var finalResults = {
      "results": {
        "email": localStorage.getItem('email'),
        "role": localStorage.getItem('role'),
        "name": localStorage.getItem('lname') + ', ' + localStorage.getItem('fname'),
        "agency": localStorage.getItem('agency'),
        "city": localStorage.getItem('city'),
        "state": localStorage.getItem('state'),
        "prime code": localStorage.getItem('prime code'),
        "sales executive": localStorage.getItem('sales executive'),
        "answers": answersCombined,
        "freeform responses": freeformsCombined,
        "overall percent": overallPercent,
        "overall score:": overallScore,
        "section 1 percent": section1Percent,
        "section 1 score": section1Score,
        "section 2 percent": section2Percent,
        "section 2 score": section2Score,
        "section 3 percent": section3Percent,
        "section 3 score": section3Score,
        "section 4 percent": section4Percent,
        "section 4 score": section4Score,
      }
    };

    var finalJsonResults = JSON.stringify(finalResults);

    var request = new XMLHttpRequest();
    // ADKN main
	  //request.open('POST', 'https://ji8uzso2mj.execute-api.us-east-1.amazonaws.com/dev/process_quiz_results', true);
    
    // ADKN stage
    request.open('POST', 'https://7n6ie77ue6.execute-api.us-east-1.amazonaws.com/dev/process_quiz_results_stage', true);
	
	// ORIGINAL Prod: 
    //request.open('POST', 'https://ooxhfmufh8.execute-api.us-east-1.amazonaws.com/Prod/submit', true);
    
    // ORIGINAL Stage: 
    //request.open('POST', 'https://804jx9iv5j.execute-api.us-east-1.amazonaws.com/Prod/submit', true);
    
    request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    request.send(finalJsonResults);
    request.onreadystatechange = function() { // Call a function when the state changes.
      if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
        console.log('request finished');
      }
    }
    
    return (
      
      <section className="pt-logo-progress-bar__partial pt-results-section">

       {this.state.showLoader ? (
          <div className="loader-container fade-in" id="loader-container">
            <div className="loader"><span id="loading-announcement" tabIndex="0">Loading</span></div>
          </div>) : (
          null
        )}

        <div id="results-container">
        {this.state.showResults ?
            <header className="pt-results-hero-card">
              <div className="row">
                <div className="col-lg-8 col-sm-12">
                        {overallScore === 'High' ?
                          <Fragment>
                            <h1 className="pt-results-hero-card__h1">
                              <img className='pt-results-hero-card__h1-img' src={require('../images/check-mark-green.svg')} alt='' />
                              <span id="your-results" tabIndex="0">Your Results</span>
                              Great Job, Keep Up the Good Work!
                            </h1>
                            <p className="pt-results-hero-card__p firstPara">Overall, you scored in the green zone, which means you are well on your way to being future ready, but it can’t stop here. Keep innovating to stay ahead. Scroll down to view your agency’s scores in each category and talk to your Travelers Sales Executive about adding new future ready capabilities. Future readiness is critical to your business to keep up with evolving consumer behavior and digital needs.
                            </p>
                             <p className="pt-results-hero-card__p">Travelers <i>toolkit</i>Plus is a great resource that hosts a plethora of marketing material to help you execute marketing that makes sense for your agency. Visit <a href="https://signin.travelers.com/?ru=https%3A%2F%2Fagenthq.travelers.com%2FAgentHQ%2FAgentHQJump.aspx%3Fj%3DZETAECARD%26hf%3Dhttps%253A%252F%252Fwww.travelerstoolkitplus.com%252Fsaml%252Findex%253Ftarget%253D%25252Fpage%25252F58" target="_blank"><i>toolkit</i>Plus</a>, check out this <a href="http://travelers.http.internapcdn.net/travelers_vitalstream_com/PIMarketing/UX_Uma.mp4" target="_blank">video</a>, and learn more about tools available in the various sections of the Future Ready Assessment below.
                             </p>
                          </Fragment>
                        :null
                        }

                        {overallScore === 'Medium' ?
                          <Fragment>
                            <h1 className="pt-results-hero-card__h1">
                              <img className='pt-results-hero-card__h1-img' src={require('../images/check-mark-yellow.svg')} alt='' />
                              <span id="your-results" tabIndex="0">Your Results</span>
                              Good Progress, But There’s More You Can Do
                            </h1>
                            <p className="pt-results-hero-card__p firstPara">Overall, you scored in the yellow zone, which means you’ve made good progress toward being future ready, but there’s more to learn to improve your agency. Scroll down to view your agency’s scores in each category and talk to your Travelers Sales Executive about adding more future ready capabilities. Future readiness is critical to your business to keep up with evolving consumer behavior and digital needs.
                            </p>
                             <p className="pt-results-hero-card__p">Travelers <i>toolkit</i>Plus is a great resource that hosts a plethora of marketing material to help you execute marketing that makes sense for your agency. Visit <a href="https://signin.travelers.com/?ru=https%3A%2F%2Fagenthq.travelers.com%2FAgentHQ%2FAgentHQJump.aspx%3Fj%3DZETAECARD%26hf%3Dhttps%253A%252F%252Fwww.travelerstoolkitplus.com%252Fsaml%252Findex%253Ftarget%253D%25252Fpage%25252F58" target="_blank"><i>toolkit</i>Plus</a>, check out this <a href="http://travelers.http.internapcdn.net/travelers_vitalstream_com/PIMarketing/UX_Uma.mp4" target="_blank">video</a>, and learn more about tools available in the various sections of the Future Ready Assessment below.
                             </p>
                          </Fragment>
                        :null
                        }

                        {overallScore === 'Low' ?
                          <Fragment>
                            <h1 className="pt-results-hero-card__h1">
                              <img className='pt-results-hero-card__h1-img' src={require('../images/x-orange.svg')} alt='' />
                              <span id="your-results" tabIndex="0">Your Results</span>
                              It’s Time to Prepare Your Business for Future Success
                            </h1>
                            <p className="pt-results-hero-card__p firstPara">Overall, you scored in the red zone, which means you have significant opportunity to improve your agency’s future readiness. Scroll down to view your agency’s scores in each category and talk to your Travelers Sales Executive about adding more future ready capabilities. Future readiness is critical to your business to keep up with evolving consumer behavior and digital needs.
                            </p>
                             <p className="pt-results-hero-card__p">Travelers <i>toolkit</i>Plus is a great resource that hosts a plethora of marketing material to help you execute marketing that makes sense for your agency. Visit <a href="https://signin.travelers.com/?ru=https%3A%2F%2Fagenthq.travelers.com%2FAgentHQ%2FAgentHQJump.aspx%3Fj%3DZETAECARD%26hf%3Dhttps%253A%252F%252Fwww.travelerstoolkitplus.com%252Fsaml%252Findex%253Ftarget%253D%25252Fpage%25252F58" target="_blank"><i>toolkit</i>Plus</a>, check out this <a href="http://travelers.http.internapcdn.net/travelers_vitalstream_com/PIMarketing/UX_Uma.mp4" target="_blank">video</a>, and learn more about tools available in the various sections of the Future Ready Assessment below.
                             </p>
                          </Fragment>
                        :null
                        }
                 
                </div>
                <div className="pt-results-hero-card__img-wrapper col-lg-4 col-sm-12 col-xs-12">
                </div>
              </div>
              <div className="pt-results-hero-card__see-more">
                <a href="#more" onClick={this.clickSeeMore}>
                  <span className="pt-results-hero-card__see-more-text">See More</span>
                  <svg height="34" width="34" viewBox="0 0 34 34">
                    <path id="path-1_2_" d="M16.4,0.4c8.5,0,15.4,6.9,15.4,15.4s-6.9,15.4-15.4,15.4S1,24.3,1,15.8S7.9,0.4,16.4,0.4z
       M16.4,3C9.3,3,3.6,8.7,3.6,15.8s5.7,12.8,12.8,12.8s12.8-5.7,12.8-12.8S23.5,3,16.4,3z M22.4,12.3c0.5,0.5,0.5,1.3,0,1.8l-5.1,5.1
      c-0.5,0.5-1.3,0.5-1.8,0l-5.1-5.1c-0.5-0.5-0.5-1.3,0-1.8s1.3-0.5,1.8,0l4.2,4.2l4.2-4.2C21.1,11.8,21.9,11.8,22.4,12.3z"/>
                  </svg>
                  <div className="pt-results-hero-card__see-more-circle">
                  </div>
                </a>
              </div>
            </header>
             :null
            }

            {this.state.showResults ?
              <div className="pt-results-email-message">Review the <b>detailed results emailed to you</b> to learn your agency's recommended focus areas and get specific ideas to help your agency become more future ready.</div>
            : null
            }
            
            {this.state.showResults ?
              <div>
                <div className="pt-results-card">
                  <div className="row pt-results-card__row">
                    <section className="col-md-8">
                      <h2 className="pt-results-card__h2" tabIndex="0" id="more">Consumer Marketing</h2>
                      
                      {section1Score === 'High' ?
                          <Fragment>
                            <p className="pt-results-card__p"><b>You scored in the green zone.</b> You are well on your way to being future ready, but it can’t stop here. Keep innovating to stay ahead. Agents in the green zone are more future ready than most, but consider taking the following steps to continue improving your consumer marketing program:
                            </p>
                          </Fragment>
                      :null
                      }

                      {section1Score === 'Medium' ?
                          <Fragment>
                            <p className="pt-results-card__p"><b>You scored in the yellow zone.</b> You’ve made good progress toward being future ready, but there’s always more to learn to improve your agency. Strong marketing is essential for reaching today’s connected customer. Consider taking the following steps to continue improving your consumer marketing program:
                            </p>
                          </Fragment>
                      :null
                      }

                      {section1Score === 'Low' ?
                          <Fragment>
                            <p className="pt-results-card__p"><b>You scored in the red zone.</b> You have significant opportunity to improve your agency’s readiness for the future. Strong marketing is essential for reaching today’s connected customer. Consider taking the following steps to improve your agency’s consumer marketing program:
                            </p>
                          </Fragment>
                      :null
                      }
                    </section>

                    <aside className="col-md-4">
                      <h3 className="pt-results-card__h3">
                        Your score
                      </h3>
                      <div className="pt-results-progress-bar-wrapper">
                        <div className="pt-progress-bar__secondary">
                          <div className="pt-progress-bar__loader-wrapper">
                            <img className="pt-progress-bar__loader" alt="" src={require('../images/prog-bar-' + section1Color + '.jpg')}  style={{width: section1Percent + '%'}}/>
                            <div className="pt-progress-bar__background"></div>
                          </div>
                          <span className="pt-progress-bar__label">Low</span>
                          <span className="pt-progress-bar__label pt-progress-bar__label--right">High</span>
                        </div>
                      </div>
                    </aside>
                  </div>

                  <div className="row pt-results-card__bottom">
                    <div className="pt-results-card__bottom--container">
                      <div className="pt-results-card__bottom--container--img section-1"></div>

                      <div className="pt-results-card__bottom--container--col">
                          <h4>Steps to continue to improve your Consumer Marketing program:</h4>
                            <ul>
                            {section1Score === 'Low' ?
                              <Fragment>
                                <li>Update your agency website on a regular basis.</li>
                              </Fragment>
                              :null
                            }

                            {section1Score === 'Medium' ?
                              <Fragment>
                                <li>Add new capabilities to your agency website.</li>
                              </Fragment>
                              :null
                            }

                            {section1Score === 'High' ?
                              <Fragment>
                                <li>Enhance your agency website with new capabilities.</li>
                              </Fragment>
                              :null
                            }
                              <li>Get more active on social media and online review sites.</li>
                              <li>Leverage carrier marketing tools and resources.</li>
                            </ul>
                          </div>
                      </div>
                    </div>
                </div>
          
                <div className="pt-results-card">
                  <div className="row pt-results-card__row">
                    <section className="col-md-8">
                      <h2 className="pt-results-card__h2">Consultative Sales</h2>
                        {section2Score === 'High' ?
                          <Fragment>
                            <p className="pt-results-card__p"><b>You scored in the green zone.</b> You are well on your way to being future ready, but it can’t stop here. Keep innovating to stay ahead. Typically, agencies in the green zone are more future ready than most and do at least these things:
                            </p>
                          </Fragment>
                        :null
                        }

                        {section2Score === 'Medium' ?
                          <Fragment>
                            <p className="pt-results-card__p"><b>You scored in the yellow zone.</b> You’ve made good progress toward being future ready, but there’s always more to learn to improve your agency. It’s important to adapt to changes in the marketplace and new customer behaviors. Consider taking the following steps to continue to improve your consultative sales process:
                            </p>
                          </Fragment>
                        :null
                        }

                        {section2Score === 'Low' ?
                          <Fragment>
                            <p className="pt-results-card__p"><b>You scored in the red zone.</b> You have significant opportunity to improve your agency’s readiness for the future. It’s important to adapt to changes in the marketplace and new customer behaviors. Consider updating your agency’s consultative sales process with the following steps:
                            </p>
                          </Fragment>
                        :null
                        }
                    </section>

                    <aside className="col-md-4">
                      <h3 className="pt-results-card__h3">
                        Your score
                      </h3>
                      <div className="pt-results-progress-bar-wrapper">
                        <div className="pt-progress-bar__secondary">
                          <div className="pt-progress-bar__loader-wrapper">
                            <img className="pt-progress-bar__loader" alt="" src={require('../images/prog-bar-' + section2Color + '.jpg')} style={{width: section2Percent + '%'}} />
                            <div className="pt-progress-bar__background"></div>
                          </div>
                          <span className="pt-progress-bar__label">Low</span>
                          <span className="pt-progress-bar__label pt-progress-bar__label--right">High</span>
                        </div>
                      </div>
                    </aside>
                  </div>
                  <div className="row pt-results-card__bottom">
                    <div className="pt-results-card__bottom--container">
                      <div className="pt-results-card__bottom--container--img section-2"></div>

                      <div className="pt-results-card__bottom--container--col">
                        <h4>Steps to continue to improve your Consultative Sales Process:</h4>
                        <ul>
                          <li>Provide materials to educate customers on coverage options.</li>
                          <li>Use video to deliver quotes and renewal reviews.</li>
                          <li>Discuss policy options with customers and promote discounts for telematics, etc.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="pt-results-card">
                  <div className="row pt-results-card__row">
                    <section className="col-md-8">
                      <h2 className="pt-results-card__h2">Customer Service</h2>
                      {section3Score === 'High' ?
                        <Fragment>
                          <p className="pt-results-card__p"><b>You scored in the green zone.</b> You are well on your way to being future ready, but it can’t stop here. Keep innovating to stay ahead. Agents in the green zone are more future ready than most and typically do at least these things:
                          </p>
                        </Fragment>
                      :null
                      }

                      {section3Score === 'Medium' ?
                        <Fragment>
                          <p className="pt-results-card__p"><b>You scored in the yellow zone.</b> You’ve made good progress toward being future ready, but there’s always more to learn to improve your agency. Customer preferences and expectations are always changing, so agencies must evolve to meet their needs. Consider taking the following steps to continue improving your customer service:
                          </p>
                        </Fragment>
                      :null
                      }

                      {section3Score === 'Low' ?
                        <Fragment>
                          <p className="pt-results-card__p"><b>You scored in the red zone.</b> You have significant opportunity to improve your agency’s readiness for the future. Customer preferences and expectations are always changing, so agencies must evolve to meet their needs. Seriously consider taking the following steps to improve your agency’s customer service:
                          </p>
                        </Fragment>
                      :null
                      }
                    </section>

                    <aside className="col-md-4">
                      <h3 className="pt-results-card__h3">
                        Your score
                      </h3>
                      <div className="pt-results-progress-bar-wrapper">
                        <div className="pt-progress-bar__secondary">
                          <div className="pt-progress-bar__loader-wrapper">
                            <img className="pt-progress-bar__loader" alt="" src={require('../images/prog-bar-' + section3Color + '.jpg')} style={{width: section3Percent + '%'}} />
                            <div className="pt-progress-bar__background"></div>
                          </div>
                          <span className="pt-progress-bar__label">Low</span>
                          <span className="pt-progress-bar__label pt-progress-bar__label--right">High</span>
                        </div>
                      </div>
                    </aside>
                  </div>

                  <div className="row pt-results-card__bottom">
                    <div className="pt-results-card__bottom--container">
                      <div className="pt-results-card__bottom--container--img section-3"></div>

                      <div className="pt-results-card__bottom--container--col">
                        <h4>Steps to continue to improve your Customer Service:</h4>
                        <ul>
                          <li>Offer extended hours and self-service options.</li>
                          <li>Document and use customers’ preferred method of communication.</li>
                          <li>Use customer texting capabilities.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pt-results-card">
                  <div className="row pt-results-card__row">
                    <section className="col-md-8">
                      <h2 className="pt-results-card__h2">Agency Culture & Operations</h2>
                        {section4Score === 'High' ?
                          <Fragment>
                            <p className="pt-results-card__p"><b>You scored in the green zone.</b> You are well on your way to being future ready, but it can’t stop here. Keep innovating to stay ahead. Agents in the green zone are more future ready than most and typically do at least these things:
                            </p>
                          </Fragment>
                        :null
                        }

                        {section4Score === 'Medium' ?
                          <Fragment>
                            <p className="pt-results-card__p"><b>You scored in the yellow zone.</b> You’ve made good progress toward being future ready, but there’s always more to learn to improve your agency. Reviewing and updating your agency operations is important for improving agency efficiency and workflow. Consider taking the following steps to continue improving your agency’s operations:
                            </p>
                          </Fragment>
                        :null
                        }

                        {section4Score === 'Low' ?
                          <Fragment>
                            <p className="pt-results-card__p"><b>You scored in the red zone.</b> You have significant opportunity to improve your agency’s readiness for the future. Reviewing and updating your agency operations is important for improving agency efficiency and workflow. Consider taking the following steps to improve your agency’s operations:
                            </p>
                          </Fragment>
                        :null
                        }
                    </section>

                    <aside className="col-md-4">
                      <h3 className="pt-results-card__h3">
                        Your score
                      </h3>
                      <div className="pt-results-progress-bar-wrapper">
                        <div className="pt-progress-bar__secondary">
                          <div className="pt-progress-bar__loader-wrapper">
                            <img className="pt-progress-bar__loader" alt="" src={require('../images/prog-bar-' + section4Color + '.jpg')} style={{width: section4Percent + '%'}}  />
                            <div className="pt-progress-bar__background"></div>
                          </div>
                          <span className="pt-progress-bar__label">Low</span>
                          <span className="pt-progress-bar__label pt-progress-bar__label--right">High</span>
                        </div>
                      </div>
                    </aside>
                  </div>
                  <div className="row pt-results-card__bottom">
                    <div className="pt-results-card__bottom--container">
                      <div className="pt-results-card__bottom--container--img section-4"></div>

                      <div className="pt-results-card__bottom--container--col">
                        <h4>Steps to continue to improve your Agency Culture and Operations:</h4>
                        <ul>
                          <li>Go digital by offering paperless communications.</li>
                          <li>Maximize your agency’s use of your agency management system.</li>
                          <li>Have 5-8 KPIs that your agency monitors and manages.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :null
            }
            {this.state.showResults ?
              <Fragment>
              <div className="pt-results-email-message">Review the <b>detailed results emailed to you</b> to learn your agency's recommended focus areas and get specific ideas to help your agency become more future ready.</div>

              <div className="pt-results-section__cta">
                <button className="btn btn-secondary cta" onClick={this.props.beginAgain}>RETAKE THE QUIZ</button>
              </div>
              </Fragment>
            :null
            }
          </div>
        </section>        
    );
  }
}

export default Results;






